<template>
  <section class="mt-6 lg:mt-8">
    <div class="container">
      <StaticticsIndex :statisticsData="currentData?.statistics" />

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 gap-5 lg:gap-7">
        <div class="lg:col-span-2 main-card p-3 lg:p-4">
          <chartIndex />
        </div>
        <div class="main-card p-3 lg:p-4">
          <HospMap />
        </div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-7">
        <!-- box - 1  -->
        <div v-if="currentData?.current_plans" class="w-full main-card px-3 p-3">
          <h3 class="text-lg font-bold text-center mb-4">Plans</h3>
          <div>Current Plans</div>
          <PlanTable :currentPlans="currentData?.current_plans?.data" />
        </div>

        <!-- box - 2  -->
        <div v-if="currentData?.logs" class="w-full main-card px-3 p-3">
          <h3 class="text-lg font-bold text-center mb-4">Logs</h3>
          <div>Log</div>
          <logTimeLine :currentLogs="currentData?.logs?.data" />
        </div>
        <!-- box - 3  -->
        <div v-if="currentData?.current_visits" class="lg:col-span-2 w-full main-card px-3 p-3">
          <h3 class="text-lg font-bold text-center mb-4">Visits</h3>
          <div>Current Visits</div>
          <VisitTable :currentVisits="currentData?.current_visits?.data" />
        </div>
      </div>

      <DashStatsQuery />
    </div>
  </section>
</template>

<script>
import DashStatsQuery from '@/services/queries/dash/DashStatsQuery.vue';
import HospMap from '@/components/dashboard/map/HospMap.vue';
import PlanTable from './blocks/planTable.vue';
import VisitTable from './blocks/visitTable.vue';
import logTimeLine from './blocks/logTimeLine.vue';
import StaticticsIndex from './blocks/StaticticsIndex.vue';
import chartIndex from './blocks/chartIndex.vue';

export default {
  components: {
    DashStatsQuery,
    PlanTable,
    VisitTable,
    logTimeLine,
    StaticticsIndex,
    chartIndex,
    HospMap,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 10,
    };
  },
  watch: {},
  computed: {
    dashQuery() {
      return this.$store.state.dash.dashStatsQuery;
    },
    currentData() {
      const q = this.$store.state.dash.dashStatsQuery;
      if (!q || q.isLoading || q.isError) {
        return [];
      }
      return q.data?.data;
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
