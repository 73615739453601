const CUSTOMER = {
  SET_CLASSES_QUERY: 'SET_CLASSES_QUERY',
  SET_BRICKS_QUERY: 'SET_BRICKS_QUERY',
  SET_ACCTYPE_QUERY: 'SET_ACCTYPE_QUERY',
  SET_CUSTOMER_QUERY: 'SET_CUSTOMER_QUERY',
  SET_CUSTOMER_INFINITE_QUERY: 'SET_CUSTOMER_INFINITE_QUERY',
  SET_ACCOUNTS_QUERY: 'SET_ACCOUNTS_QUERY',
  SET_PHARMACYGROUP_QUERY: 'SET_PHARMACYGROUP_QUERY',
  SET_CUSTOMER_CHART_QUERY: 'SET_CUSTOMER_CHART_QUERY',
};

export default CUSTOMER;
