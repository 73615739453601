import { CUSTOMER } from '../types';

export default {
  state: {
    classesQuery: null,
    bricksQuery: null,
    acctypeQuery: null,
    customerQuery: null,
    customerInfiniteQuery: null,
    accountsQuery: null,
    pharmacyGroupQuery: null,
    customerchartQuery: null,
  },
  getters: {},
  mutations: {
    [CUSTOMER.SET_CLASSES_QUERY](state, payload) {
      state.classesQuery = payload;
    },

    [CUSTOMER.SET_BRICKS_QUERY](state, payload) {
      state.bricksQuery = payload;
    },

    [CUSTOMER.SET_ACCTYPE_QUERY](state, payload) {
      state.acctypeQuery = payload;
    },

    [CUSTOMER.SET_CUSTOMER_QUERY](state, payload) {
      state.customerQuery = payload;
    },
    [CUSTOMER.SET_CUSTOMER_INFINITE_QUERY](state, payload) {
      state.customerInfiniteQuery = payload;
    },

    [CUSTOMER.SET_ACCOUNTS_QUERY](state, payload) {
      state.accountsQuery = payload;
    },

    [CUSTOMER.SET_PHARMACYGROUP_QUERY](state, payload) {
      state.pharmacyGroupQuery = payload;
    },

    [CUSTOMER.SET_CUSTOMER_CHART_QUERY](state, payload) {
      state.customerchartQuery = payload;
    },

  },
  actions: {},
};
